.Header {
    position: sticky;
    top: 0;
    z-index: 120;
    padding: 1em 0;
    box-shadow: $box-shadow;
    background-color: $color-secondary;
    color: $color-white;
}

.Header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Header-title {
    margin: 0;
    color: $color-white;

    @include media-query($breakpoint-xxl) {
        order: 0;
    }
}

.Header-nav {
    @include media-query($breakpoint-xxl) {
        order: 1;
    }
}

.Header-login {
    @include media-query($breakpoint-xxl) {
        order: 2;
    }
}

.Header-languages {
    @include media-query($breakpoint-xxl) {
        order: 1;
    }
}