
.Card {
    position: relative;
    text-decoration: none;
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.Card--hover {
    cursor: pointer;

    &:active,
    &:focus,
    &:hover {

        .Card-button {
            margin-right: -0.5em
        }

        .Card-figureImage {
            transform: translate(-50%, -50%) scale(1.1);
        }
    }
}

.Card-figure {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 60%;
    overflow: hidden;
}

.Card-figureImage {
    min-height: 100%;
	width: 100%;
	height: auto;
	position: absolute;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    transition: all 0.3s ease-in-out;
}

.Card-category {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $color-primary-light;
    border-bottom-left-radius: $border-radius;
    color: $color-white;
    font-size: 0.8em;
    padding: 0.25em 1em;
    text-transform: lowercase;
}

.category .Card-category {
    display: none;
}

.Card-content {
    padding: 0.25em 1em;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.Card-title {
    margin-bottom: 0.25em;
}

.Card-meta {
    font-size: 0.8em;
    margin-bottom: 1em;
    color: $color-gray;
}

.Card-intro {
    font-size: 0.9em;
}

.Card-button {
    color: $color-primary;
    font-size: 0.9em;
    margin-left: auto;
    margin-top: auto;
    transition: all 0.3s ease-in-out;
}