.Hero {
    min-height: 40vh;
    overflow: hidden;
    position: relative; 
    background-color: $color-black;
    color: $color-white;
}

.Hero--small {
    min-height: 25vh;
    .Hero-content {
        min-height: 25vh;
    }
}

.Hero-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: $color-white;
    position: relative;
    text-align: left;
    width: 100%;
    color: $color-white;
    min-height: 40vh;
}

.Hero-container {
    width: 100%;
}

.Hero-figure {
    position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
    bottom: 0;
    overflow: hidden;
    background: $color-black;

    &:after {
		content: '';
        // background-color: rgba($color: #000000, $alpha: 0.25);
        position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		// z-index: 1;
		bottom: 0;
	}
}

.Hero-figureImage {
	min-height: 100%;
	min-width: 750px;
	width: 100%;
	height: auto;
	position: absolute;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
}

.Hero-continue {
    position: absolute;
    bottom: 6em;
    cursor: pointer;
    text-align: center;
    width: 100%;
    display: none;
}