.Background {
    padding: 3em 0;
    position: relative;
}

.Background--vh {
    min-height: calc(100vh - 110px - 50px);
    display: flex;
    align-items: center;
}

.Background--primary {
    color: $color-white;
    background-color: $color-primary;

    h1,h2,h3,h4 {
        color: $color-white;
    }
}

.Background--secondary {
    color: $color-white;
    background-color: $color-secondary;

    h1,h2,h3,h4 {
        color: $color-white;
    }
}