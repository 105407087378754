.menu {
    position: relative;

	// Remove bullets
	list-style: none;
    margin-bottom: 0;
    
	// Remove default space allocated for bullets
    margin-top: 0;
    padding: 0;
    text-align: center;

    display: flex;
    flex-wrap: wrap;
 
    .current-menu-item a {

        .menu-image {
            filter: grayscale(0%) contrast(100%);
        }

        &:after {
            background-color: rgba(0, 0, 0, 0);
            opacity: 0;
        }
    }

    > .menu-item {
        width: 100%;
        min-height: calc( calc(100vh - 110px) / 5 );
        overflow: hidden;
        display: flex;

        @include media-query($breakpoint-xl) {
            width: 20%;
            min-height: calc(100vh - 110px);
        }
        
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            width: 100%;
            text-decoration: none;
            flex-direction: column;

            &:active,
            &:focus,
            &:hover {

                .menu-image {
                    filter: grayscale(0%) contrast(100%);
                }

                &:after {
                    background-color: rgba(0, 0, 0, 0);
                }
            }

           
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                background-color: rgba($color-primary, 0.5);
                transition: all 0.3s ease-in-out;
                z-index: 1;
            }
        }
    }
    
    .menu-image-hover-wrapper {
        position: absolute;
        padding-right: 0 !important;
        width: 100%;
        padding-bottom: 56.3%;
        height: 0;
        overflow: hidden;
    }

    .hovered-image {
        margin: 0 !important;
        filter: none;
    }

    .hovered-image,
    .menu-image {
        padding-right: 0px !important;
        padding-bottom: 0px !important;
        min-height: 100%;
        width: 100%;
        height: auto;
        // min-height: 1px;
        position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // filter: grayscale(100%) contrast(125%);
        object-fit: cover;
        @include object-fit(cover);
        transition: all 0.3s ease-in-out;

    }
    
    .menu-image-title {
        z-index: 2;
        font-size: 1.75em;
        // text-shadow: 0px 1px 2px black;
        font-family: $font-heading;
        text-transform: lowercase;
    }

    .menu-item-description {
        z-index: 2;
        margin-top: -0.5em;
    }

    .sub-menu {
        padding: 0;
    }
}

