.Form {
	width: 100%;
	margin: 0 auto;
}

.Form-field {
	margin-bottom: 1em;
	position: relative;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	.Form-input {
		width: 100%;
	}
}

.Form-label {
	text-align: left;
	font-size: 0.85rem;
	opacity: 0.5;
}

.Form-input {
	padding: 0.3em 0.75em;
	border: 0;
	border-radius: $border-radius;
	font-size: 0.95em;
    color: $color-primary-light;
	
	&::placeholder {
		color: $color-primary-light;
	}
}

.Form-textarea {
	padding: 0.3em 0.75em;
	border: 0;
	border-radius: $border-radius;
	font-size: 0.95em;
	color: $color-primary-light;
	width: 100%;
}

.Form-select {
	appearance: none;
	padding: 0.2em 2em 0.2em 0.5em;
	border: 0;
	border-radius: $border-radius;
	font-size: 0.95em;
	position: relative;
	cursor: pointer;
	color: $color-primary-light;
}

.wpcf7 {

	.Form-submit {
		position: relative;
		text-align: right;
		
	}
	.Form-submitButton {
		background-color: $color-white;
		color: $color-primary;
		appearance: none;
	
		&:hover,
		&:active
		&:focus {
			background: $color-white;
			color: $color-primary-light;
		}
	}

	.ajax-loader {
		display: none !important;
	}
	
	.wpcf7-aborted {
		border:	0;
		background: #942527;
		border-radius: 10px;
		text-align: center;
	}

	.wpcf7-mail-sent-ng {
		border:	0;
		background: #628a38;
		border-radius: 10px;
		text-align: center;
	}
}

// Search
.Form--search {
    display: flex;
    justify-content: space-between;
	width: auto;
	position: relative;
	max-width: 35em;
    margin: 1em auto;

	.Form-input {
		width: 100%;
		// Make room for absolute search icon
		padding-right: 2.25em;
	}
    
    .Form-field {
        width: auto;
	}

	.Form-submit {
		position: absolute;
		right: 0.5em;
		top: -0.1em;
        cursor: pointer;
        z-index: 2;
		color: $color-primary;
		font-size: 1.4em;
	}
}

// Round
.Form--round {

	.Form-input {
		border-radius: $border-radius-round;
	}
}


.Form--border {

	.Form-input {
		border: 1px solid $color-gray;
	}
}