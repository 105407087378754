.Footer {
    background-color: $color-secondary;
    color: $color-white;
    padding-top: 1em;
    padding-bottom: 1em;
    // margin-top: 3em;
    font-size: 0.8em;
    width: 100%;
    // background-image: url('../kanayachts/assets/images/wood.png');
    // background-repeat: repeat;

    p {
        margin-bottom: 0;
    }

    h1,h2,h3,h4 {
        color: $color-white;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            color: $color-secondary-dark;
        }
    }
}

.Footer-title {
    font-size: 1.75em;
    color: $color-white;
}