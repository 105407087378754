.Article {

}

.Article-title {
    font-size: 2.5em;
    margin-bottom: 0.25em;
}

.Article-meta {
    margin-bottom: 2em;
    font-size: 0.9em;
    color: $color-gray;
}

.Page-content,
.Article-content {

    h4 {
        margin-bottom: 0.25em;
        color: $color-gray-dark;
        margin-top: 0;
    }

    p {
        margin-bottom: 1em;
        font-size: 0.9em;
        line-height: 2em;
    }
    
    .aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
    }   

    .alignleft,
    .left {
            margin-right: 1em;
            margin-bottom: 0.35em;
            margin-top: 0.35em;
            float: left;
    }

    .alignright,
    .right {
            margin-left: 1em;
            margin-bottom: 0.35em;
            margin-top: 0.35em;
            float: right;
    }

    blockquote {
        font-size: 2em;
        font-weight: 600;
        border-left: 2px solid $color-primary-light;
        padding-left: 1em;
        color: $color-primary-light;
        font-family: $font-heading;
        p {
            line-height: 1.2;
            margin-bottom: 0;
        }
        cite {
            font-size: 0.5em;
            color: $color-gray;
        }
    }
}

