$breakpoint-m:			30em; //  480px
$breakpoint-ml:         35em;
$breakpoint-l:			40em; //  640px
$breakpoint-xl:			50em; //  880px
$breakpoint-xxl:		60em; //  960px
$breakpoint-xxxl:		80em; // 1280px
$breakpoint-xxxxl:		90em; // 1280px

// Fonts
$font-fallback-stack: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-default: 'Comfortaa', cursive;
$font-heading: 'Comfortaa', cursive;

$color-gray-light:      #ddd;
$color-gray:            #ccc;
$color-gray-dark:       #444; 
$color-black:           #000;
$color-white:           #fff;

$color-primary-light:   #83A7B0;
$color-primary:         #5f8c8a;
$color-primary-dark:    #507b7a;

$color-secondary-light: #E4D3BE;
$color-secondary:       #D2C1A1;
$color-secondary-dark:  #B3988D;

// Specials
$border-radius: 4px;
$border-radius-round: 6em;
$box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.1);