.ms-bullet {
    border: 1px solid white;
    background: none !important;
    width: 0.6em !important;
    height: 0.6em !important;
    border-radius: 100%;
}

.ms-bullet-selected {
    background-color: white !important;
}

.ms-slide-info {
    left: 5%;
    color: #343980;
    bottom: 15% !important;
    font-weight: 600;
}

@media screen and (min-width: 880px) {
    .ms-slide-info {
    width: 35% !important;
    left: 10%;
    }
    .ms-slide-info h2 {
    font-size: 2em;
    }
    .ms-slide-info a {
        display: inline-block !important;
    }
}

.ms-slide-info a {
    background: #ee7544;
    color: white;
    padding: 0.9em 2em 0.8em;
    text-decoration: none;
    border-radius: 4em;
    text-transform: uppercase;
    font-size: 0.7em;
    font-weight: 700;
    letter-spacing: 2px;
    display: none;
}

