.u-sizeFit {
	flex-basis: auto;
	width: auto;
}

.u-sizeFill {
	flex-grow: 1;
	width: auto;
}

/**
 * Sizing utilities
 *
 * Per breakpoint available
 */

.u-size1of1 {
	width: percentage(1/1);
}

.u-size1of2 {
	width: percentage(1/2);
}

.u-size1of3 {
	width: percentage(1/3);
}

.u-size2of3 {
	width: percentage(2/3);
}

// Breakpoint M
@include media-query($breakpoint-m) {

	.u-m-size1of2 {
		width: percentage(1/2);
	}

    .u-m-size1of3 {
		width: percentage(1/3);
	}

    .u-m-size1of4 {
		width: percentage(1/4);
	}

	.u-m-size1of6 {
		width: percentage(1/6);
	}
}

// Breakpoint L
@include media-query($breakpoint-l) {

	.u-l-size1of2 {
		width: percentage(1/2);
	}

	.u-l-size1of3 {
		width: percentage(1/3);
	}

	.u-l-size2of3 {
		width: percentage(2/3);
	}

	.u-l-size1of4 {
		width: percentage(1/4);
	}

	.u-l-size3of4 {
		width: percentage(3/4);
	}

}

// Breakpoint XL
@include media-query($breakpoint-xl) {

	.u-xl-size1of1 {
		width: percentage(1/1);
	}

	.u-xl-size1of2 {
		width: percentage(1/2);
	}

	.u-xl-size1of3 {
		width: percentage(1/3);
	}

	.u-xl-size2of3 {
		width: percentage(2/3);
	}

	.u-xl-size1of4 {
		width: percentage(1/4);
	}

	.u-xl-size3of4 {
		width: percentage(3/4);
	}

	.u-xl-size1of5 {
		width: percentage(1/5);
	}

	.u-xl-size3of5 {
		width: percentage(3/5);
	}
}

// Breakpoint xxl
@include media-query($breakpoint-xxl) {

	.u-xxl-size1of3 {
		width: percentage(1/3);
	}

	.u-xxl-size1of4 {
		width: percentage(1/4);
	}

}

// Breakpoint xxl
@include media-query($breakpoint-xxxl) {
	.u-xxxl-size1of4 {
		width: percentage(1/4);
	}

	.u-xxxl-size2of12 {
		width: percentage(2/12);
	}
	.u-xxxl-size3of12 {
		width: percentage(3/12);
	}
	.u-xxxl-size5of12 {
		width: percentage(5/12);
	}
	.u-xxxl-size8of12 {
		width: percentage(8/12);
	}
}