.alm-btn-wrap {
    // margin-top: -4.8em;
    padding: 25px 0 25px !important;
}

// #ajax-load-more {
//     width: 100%;
// }

.ajax-load-more-wrap.purple .alm-load-more-btn {
    background-color: #46394b !important;
}